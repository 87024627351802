import { Book } from "../../../types/Book";

type BaseModalProps = {
  open: boolean;
  onClose: () => void;
};

export const modalTypes = {
  auth: "auth",
  book: "book",
  checkout: "checkout",
  scanner: "scanner",
  del: "del",
  moveShelf: "moveShelf",
} as const;

export type ModalTypes = keyof typeof modalTypes;

export type AuthModalProps = {
  type: "auth";
  isUpdate?: boolean;
} & BaseModalProps;

export type BookModalProps = {
  type: "book";
  book?: Book;
} & BaseModalProps;

export type CheckoutBookModalProps = {
  type: "checkout";
} & BaseModalProps &
  Pick<Book, "uuid" | "title"> &
  Partial<Pick<Book, "checkoutBy" | "contact">>;

export type ScannnerModal = {
  type: "scanner";
  onDetect: (isbn: string) => void;
} & BaseModalProps;

export type DeleteBookModalProps = { type: "del" } & BaseModalProps &
  Pick<Book, "uuid" | "title">;

export type MoveShelfModalProps = { type: "moveShelf" } & BaseModalProps & {
    books: Book["uuid"][];
  };

export interface MoveShelfAction {
  target: Book["shelf"];
  books: Book["uuid"][];
}

export type ActiveModalProps =
  | Omit<AuthModalProps, "open" | "onClose">
  | Omit<BookModalProps, "open">
  | Omit<CheckoutBookModalProps, "open">
  | Omit<ScannnerModal, "open" | "onClose">
  | Omit<DeleteBookModalProps, "open">
  | Omit<MoveShelfModalProps, "open">;

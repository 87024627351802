import React, { useCallback } from "react";
import { Pagination as BP } from "react-bootstrap";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useAuth } from "../../../shared/utils/useAuthentication";

export const Pagination = ({
  currentPage,
  getPageCount,
  setPageIndex,
}: {
  currentPage: number;
  getPageCount: () => number;
  setPageIndex: (index: number) => void;
}): React.JSX.Element => {
  const { authenticated } = useAuth();

  const getPage = useCallback(
    (n: number) => (
      <BP.Item
        key={`${n}th-page`}
        active={n === currentPage}
        onClick={() => setPageIndex(n)}
      >
        {n + 1}
      </BP.Item>
    ),
    [setPageIndex, currentPage]
  );

  return (
    <BP
      className='d-flex'
      style={{
        marginLeft: "auto",
        marginRight: authenticated ? "10px" : "auto",
      }}
    >
      <BP.Item
        key='prev'
        disabled={currentPage === 0}
        onClick={() => setPageIndex(currentPage - 1)}
      >
        <AiOutlineLeft />
      </BP.Item>
      {getPage(0)}
      {currentPage > 3 && <BP.Ellipsis />}
      {currentPage === 3 && getPage(currentPage - 2)}
      {currentPage > 1 && getPage(currentPage - 1)}
      {currentPage > 0 && getPage(currentPage)}
      {currentPage < getPageCount() - 2 && getPage(currentPage + 1)}
      {currentPage < getPageCount() - 3 && getPageCount() > 4 && (
        <BP.Ellipsis />
      )}
      {currentPage < getPageCount() - 1 && getPage(getPageCount() - 1)}
      <BP.Item
        key='next'
        onClick={() => setPageIndex(currentPage + 1)}
        disabled={currentPage >= getPageCount() - 1}
      >
        <AiOutlineRight />
      </BP.Item>
    </BP>
  );
};

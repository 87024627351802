import { useMutation } from "@tanstack/react-query";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ImBook } from "react-icons/im";
import { ModalHeader } from "./ModalHeader";
import { MoveShelfAction, MoveShelfModalProps } from "./types";
import { useForm, useWatch } from "react-hook-form";
import { bookShelfs } from "../../../types/Book";

export const MoveShelfModal = ({
  books,
  open,
  onClose,
}: Omit<MoveShelfModalProps, "type">): React.JSX.Element => {
  const { control, register, formState, setError } = useForm<MoveShelfAction>({
    mode: "onChange",
  });

  const values = useWatch({ control });

  const { mutate: mv } = useMutation({
    mutationFn: async () => {
      if (!values.target) {
        setError("target", { message: "Taget shelf is required" });
        return;
      }

      await fetch(`/api/shelf/move`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, books }),
      });
      onClose();
    },
  });

  return (
    <Modal show={open} onHide={onClose} centered>
      <ModalHeader
        onClose={onClose}
        title={"Move to Shelf"}
        icon={<ImBook size={50} className='ml-0 mr-auto' />}
      />
      <Modal.Body
        style={{
          borderTop: "none",
        }}
      >
        <Form.Group as={Row} className='mb-2'>
          <Col sm='2'>
            <Form.Label>Shelf</Form.Label>
          </Col>
          <Col>
            <Form.Select
              {...register("target", { required: true })}
              isInvalid={!!formState.errors.target}
            >
              {Object.keys(bookShelfs).map((key) => (
                <option
                  key='key'
                  value={bookShelfs[key as keyof typeof bookShelfs]}
                >
                  {key}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
              {!values.target
                ? "Target shelf is required"
                : formState.errors.target?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div className='d-flex mx-auto mb-auto mt-2 w-100'>
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "0px",
              marginRight: "10px",
            }}
            onClick={() => mv()}
          >
            Move
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

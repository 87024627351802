import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { ImBin, ImBook, ImBoxAdd, ImBoxRemove, ImMenu } from "react-icons/im";
import { ModalContextType } from "../../../App";
import { modalTypes } from "../../../shared/components/modals/types";
import { Book } from "../../../types/Book";

const { book: bookModal, checkout, del } = modalTypes;

export const Actions = ({
  refetch,
  book,
  authenticated,
  setActiveModal,
}: {
  refetch: () => void;
  book: Book;
  authenticated: boolean;
} & Pick<ModalContextType, "setActiveModal">): React.JSX.Element => {
  const { mutate: re } = useMutation({
    mutationFn: async () => {
      await fetch(`/api/books/return`, {
        method: "POST",
        body: JSON.stringify({
          checkoutBy: "admin",
          contact: "admin",
          uuid: book.uuid,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      refetch();
    },
  });

  const onClose = useCallback(() => {
    refetch();
    setActiveModal();
  }, [refetch, setActiveModal]);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant='success' id='dropdown-basic'>
          <ImMenu size={25} color='black' />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {authenticated && (
            <Dropdown.Item
              id='moveToShelf'
              className='d-flex'
              onClick={() => setActiveModal({ type: bookModal, book, onClose })}
            >
              <Badge pill className='ml-2 d-flex mr-2'>
                <ImBook size={25} color='black' className='m-auto' />
              </Badge>
              <p className='m-auto' style={{ paddingLeft: "10px" }}>
                Edit Book
              </p>
            </Dropdown.Item>
          )}
          {!book.checkoutBy || book.checkoutBy === "null" ? (
            <Dropdown.Item
              id='checkout'
              className='d-flex'
              onClick={() =>
                setActiveModal({
                  type: checkout,
                  title: book.title,
                  uuid: book.uuid,
                  onClose,
                })
              }
            >
              <Badge pill className='ml-2 d-flex mr-2'>
                <ImBoxRemove size={25} color='black' className='m-auto' />
              </Badge>{" "}
              <p className='m-auto' style={{ paddingLeft: "10px" }}>
                Checkout
              </p>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              id='return'
              className='d-flex'
              onClick={() =>
                authenticated
                  ? re()
                  : setActiveModal({ type: checkout, ...book, onClose })
              }
            >
              <Badge
                pill
                className='ml-2 d-flex mr-2'
                style={{ border: "2px solid black" }}
              >
                <ImBoxAdd size={25} color='black' className='m-auto' />
              </Badge>{" "}
              <p className='m-auto' style={{ paddingLeft: "10px" }}>
                Return
              </p>
            </Dropdown.Item>
          )}
          {authenticated && (
            <Dropdown.Item
              id='delete'
              className='d-flex'
              onClick={() => setActiveModal({ type: del, onClose, ...book })}
            >
              <Badge pill className='ml-2 d-flex danger mr-2'>
                <ImBin size={25} color='black' className='m-auto' />
              </Badge>{" "}
              <p className='m-auto' style={{ paddingLeft: "10px" }}>
                Delete
              </p>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

import { useMutation } from "@tanstack/react-query";
import { Button, Modal } from "react-bootstrap";
import { ImBin } from "react-icons/im";
import { ModalHeader } from "./ModalHeader";
import { DeleteBookModalProps } from "./types";

export const DeleteBookModal = ({
  uuid,
  title,
  open,
  onClose,
}: Omit<DeleteBookModalProps, "type">): React.JSX.Element => {
  const { mutate: rm } = useMutation({
    mutationFn: async () => {
      await fetch(`/api/books/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid }),
      });
      onClose();
    },
  });

  return (
    <Modal show={open} onHide={onClose} centered>
      <ModalHeader
        onClose={onClose}
        title={`Delete Book "${title}"`}
        icon={<ImBin size={50} className='ml-0 mr-auto' />}
      />
      <Modal.Body
        style={{
          borderTop: "none",
        }}
      >
        Do you really want to delete this book?
        <div className='d-flex mx-auto mb-auto mt-2 w-100'>
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={() => rm()}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

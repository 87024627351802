import React from "react";
import { AuthenticationModal } from "./AuthenticationModal";
import { modalTypes } from "./types";
import { BookModal } from "./BookModal";
import { CheckoutBookModal } from "./CheckoutModal";
import { ScannerModal } from "./ScannerModal";
import { DeleteBookModal } from "./DeleteBookModal";
import { ModalContextType } from "../../../App";
import { MoveShelfModal } from "./MoveShelfModal";

const { auth, book, checkout, scanner, del, moveShelf } = modalTypes;

export const ModalSelector = ({
  activeModal,
  setActiveModal,
}: ModalContextType): React.JSX.Element => (
  <>
    <AuthenticationModal
      open={activeModal?.type === auth}
      onClose={setActiveModal}
      isUpdate={activeModal?.type === auth ? activeModal.isUpdate : undefined}
    />
    <BookModal
      open={activeModal?.type === book}
      onClose={
        activeModal?.type === book ? activeModal.onClose : setActiveModal
      }
      book={activeModal?.type === book ? activeModal.book : undefined}
    />
    <CheckoutBookModal
      open={activeModal?.type === checkout}
      onClose={
        activeModal?.type === checkout ? activeModal.onClose : setActiveModal
      }
      uuid={activeModal?.type === checkout ? activeModal.uuid : ""}
      checkoutBy={
        activeModal?.type === checkout ? activeModal.checkoutBy : undefined
      }
      title={activeModal?.type === checkout ? activeModal.title : ""}
      contact={activeModal?.type === checkout ? activeModal.contact : undefined}
    />
    <ScannerModal
      open={activeModal?.type === scanner}
      onClose={setActiveModal}
      onDetected={
        activeModal?.type === scanner ? activeModal.onDetect : undefined
      }
    />
    <DeleteBookModal
      open={activeModal?.type === del}
      onClose={activeModal?.type === del ? activeModal.onClose : setActiveModal}
      uuid={activeModal?.type === del ? activeModal.uuid : ""}
      title={activeModal?.type === del ? activeModal.title : ""}
    />
    <MoveShelfModal
      books={activeModal?.type === moveShelf ? activeModal.books : []}
      open={activeModal?.type === moveShelf}
      onClose={
        activeModal?.type === moveShelf ? activeModal.onClose : setActiveModal
      }
    />
  </>
);

import { XMLParser } from "fast-xml-parser";
import { Book } from "../../../types/Book";

export const tryDeutscheNationalBibliothekApi = async (
  isbn: string
): Promise<Pick<Book, "published" | "title"> | undefined> => {
  const parser = new XMLParser();

  const foundBooks = parser.parse(
    await (
      await fetch(
        "https://services.dnb.de/sru/dnb?version=1.1&operation=searchRetrieve&query=" +
          isbn
      )
    ).text()
  );
  const foundBook =
    foundBooks.searchRetrieveResponse?.records?.record?.recordData?.[
      "rdf:RDF"
    ]?.["rdf:Description"];

  if (!!foundBook) {
    return {
      published: foundBook["dcterms:issued"],
      title: foundBook["dc:title"],
    };
  }
  return undefined;
};

import React, { useCallback, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ImBoxAdd, ImBoxRemove } from "react-icons/im";
import { Book } from "../../../types/Book";
import { ModalHeader } from "./ModalHeader";
import { CheckoutBookModalProps } from "./types";

type BookCheckoutForm = Pick<Book, "checkoutBy" | "contact">;

export const CheckoutBookModal = ({
  uuid,
  title,
  open,
  onClose,
  checkoutBy,
  contact,
}: Omit<CheckoutBookModalProps, "type">): React.JSX.Element => {
  const isChechout = !checkoutBy && !contact;

  const [failed, setFailed] = useState<string>();

  const { control, register, formState, setError } = useForm<BookCheckoutForm>({
    mode: "onChange",
  });

  const values = useWatch({ control });

  const onSubmit = useCallback(
    async (data: Partial<BookCheckoutForm>) => {
      if (!formState.isValid) {
        return;
      }
      if (!data.checkoutBy) {
        setError("checkoutBy", {
          message: `please enter ${
            isChechout ? "your" : "the"
          } name or nickname ${isChechout ? "" : "you prvided on checkout"}`,
        });
      }
      if (!data.contact) {
        setError("contact", {
          message: `please enter ${
            isChechout ? "some" : "the"
          } contact information ${isChechout ? "" : "you prvided on checkout"}`,
        });
      }
      const res = await fetch(
        isChechout ? "/api/books/checkout" : "/api/books/return",
        {
          method: "POST",
          body: JSON.stringify({ ...data, uuid }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        onClose();
      } else {
        setFailed(await res.text());
      }
    },
    [formState.isValid, isChechout, uuid, setError, onClose]
  );

  return (
    <Modal show={open} onHide={onClose} centered>
      <ModalHeader
        onClose={onClose}
        title={`${isChechout ? "Checkout" : "Return"} book '${title}'`}
        icon={
          isChechout ? (
            <ImBoxRemove size={50} className='ml-0 mr-auto' />
          ) : (
            <ImBoxAdd size={50} className='ml-0 mr-auto' />
          )
        }
      />
      <Modal.Body
        style={{
          borderTop: "none",
        }}
      >
        <Alert className='w-80 m-auto my-4' variant='warning'>
          <AiOutlineExclamationCircle />
          Your contact information will only be visible to the admins and is
          deleted once you have returned this book.
        </Alert>
        <Form
          className='mb-2'
          onSubmit={(ev) => {
            ev.preventDefault();
            onSubmit(values);
          }}
        >
          <Form.Group as={Row} className='mb-2'>
            <Col sm='2'>
              <Form.Label>Name</Form.Label>
            </Col>
            <Col className='d-flex flex-column'>
              <Form.Control
                {...register("checkoutBy", {
                  required: true,
                  validate: {
                    notNullString: (value) => {
                      return (
                        value?.toLocaleLowerCase() !== "null" ||
                        "'null' is not a valid nickname"
                      );
                    },
                    notUndefinedString: (value) => {
                      return (
                        value?.toLocaleLowerCase() !== "undefined" ||
                        "'undefined' is not a valid nickname"
                      );
                    },
                  },
                })}
                isInvalid={!!formState.errors.checkoutBy}
                placeholder={isChechout ? "name or nickname" : checkoutBy ?? ""}
                style={{
                  borderRadius: "5px 0px 0px 5px",
                }}
              />
              <Form.Control.Feedback type='invalid'>
                {!values.checkoutBy
                  ? "name is required"
                  : formState.errors.checkoutBy?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-2'>
            <Col sm='2'>
              <Form.Label>Contact Info</Form.Label>
            </Col>
            <Col className='d-flex flex-column'>
              <Form.Control
                {...register("contact", {
                  required: true,
                  validate: {
                    //function to test if contact is a valid email or phone number
                    validContact: (value) => {
                      if (
                        value &&
                        (value.match(/^[0-9]{11}$/) ||
                          value.match(
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                          ))
                      ) {
                        return true;
                      }
                      return "email or phone number is required";
                    },
                  },
                })}
                placeholder={
                  isChechout ? "email or phone number" : contact ?? ""
                }
                isInvalid={!!formState.errors.contact}
              />

              <Form.Control.Feedback type='invalid'>
                {!values.contact
                  ? "Some contact information is required"
                  : formState.errors.contact?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Control.Feedback
            style={{ display: !failed ? "none" : "flex" }}
            type='invalid'
          >
            <p className='mr-0'>{failed}</p>
          </Form.Control.Feedback>
          <div className='d-flex mx-auto mb-auto mt-2 w-100'>
            <Button
              style={{
                borderRadius: "5px",
                marginLeft: "auto",
                marginRight: "10px",
              }}
              disabled={!formState.isValid}
              onClick={() => onSubmit(values)}
            >
              {`${isChechout ? "Checkout" : "Return"} book`}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

import React from "react";
import { Modal } from "react-bootstrap";
import { ImCamera } from "react-icons/im";
import { useScanner } from "../../utils/useScanner";
import { ModalHeader } from "./ModalHeader";

export const ScannerModal = ({
  open,
  onClose,
  onDetected,
}: {
  open: boolean;
  onClose: () => void;
  onDetected?: (isbn: string) => void;
}): React.JSX.Element => {
  const { scannerError, setScannerRef } = useScanner({ onDetected });

  return (
    <Modal show={open} onHide={onClose} centered>
      <ModalHeader
        onClose={onClose}
        title={"Scan barcode"}
        icon={<ImCamera size={50} className='ml-0 mr-auto' />}
      />
      <Modal.Body>
        <div
          className='w-100 overflow-hidden'
          ref={(ref) => setScannerRef(ref)}
          style={{
            position: "relative",
            height: "25vh",
          }}
        >
          <canvas
            className='drawingBuffer w-100 position-absolute'
            style={{
              height: "100%",
              border: `2px solid black`,
            }}
          />
        </div>
        {scannerError ? (
          <p>
            ERROR INITIALIZING CAMERA ${JSON.stringify(scannerError)} -- DO YOU
            HAVE PERMISSION?
          </p>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

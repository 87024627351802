import { Book } from "../../../types/Book";

export const tryGoogleBooksApi = async (
  isbn: string
): Promise<Pick<Book, "published" | "title"> | undefined> => {
  const googleBooks = await (
    await fetch("https://www.googleapis.com/books/v1/volumes?q=isbn:" + isbn)
  ).json();
  if ("items" in googleBooks) {
    return {
      published: googleBooks.items[0].volumeInfo.publishedDate.substring(0, 4),
      title: googleBooks.items[0].volumeInfo.title,
    };
  }
  return undefined;
};
